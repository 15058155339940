<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null && permission.read_perm == 1">
      <div v-if="getLoginPayroll">
        <div class="fluid" id="payrollList">
          <div>
            <div class="payroll-list-dev">
              <v-card elevation="0" class="px-3 py-2" style="width: 100%">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex"
                    style="
                      position: relative;
                      padding: 0 20px;
                      text-align: center;
                      margin-top: 20px;
                    "
                  >
                    <h4
                      class="indigo--text"
                      style="font-weight: bold; margin: auto"
                    >
                      Payroll
                      <v-tooltip bottom color="grey">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            x-small
                            text
                            icon
                            color="grey darken-2"
                            @click="getDataFromApi"
                          >
                            <v-icon small>mdi-refresh</v-icon>
                          </v-btn>
                        </template>
                        <span>refresh page</span>
                      </v-tooltip>
                    </h4>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <v-toolbar
                        class="payroll-list-toolbar-1"
                        flat
                        style="height: 60px; border-radius: 10px 10px 0 0"
                      >
                        <v-btn
                          outlined
                          v-if="
                            permission.create_perm == 1 &&
                            permission.update_perm == 1
                          "
                          elevation="1"
                          color="indigo"
                          class="indigo--text font-weight-bold"
                          style="font-size: 12px; margin-right: 10px"
                          @click="openGenerateDialog"
                          :disabled="loading"
                        >
                          Generate Payroll
                        </v-btn>
                        <div
                          class="payroll-list-toolbar-1"
                          style="width: 300px; margin-left: auto"
                        >
                          <v-text-field
                            v-model="paramAPI.keyword"
                            label="cari name"
                            type="search"
                            outlined
                            dense
                            append-icon="mdi-magnify"
                            style="margin-left: 10px; font-size: 12px"
                            @keyup.enter="searchEnter"
                            :disabled="loading"
                          ></v-text-field>
                        </div>
                      </v-toolbar>
                      <v-toolbar
                        class="payroll-list-toolbar-1"
                        flat
                        style="
                          height: 60px;
                          border-radius: 10px 10px 0 0;
                          padding-top: 15px;
                        "
                      >
                        <div class="d-flex align-content-start">
                          <div style="width: 210px">
                            <v-autocomplete
                              v-model="paramAPI.company_id"
                              label="Perusahaan"
                              item-text="name"
                              item-value="id"
                              style="font-size: 12px"
                              return-id
                              dense
                              outlined
                              clearable
                              @change="companyWatcher"
                              :items="dropdown.company"
                              :disabled="isDisableCompanyDropdown"
                            ></v-autocomplete>
                          </div>
                          <!-- <div style="width: 210px; margin-left: 15px">
                            <v-autocomplete
                              v-model="paramAPI.payroll_template_id"
                              label="Template"
                              item-text="name"
                              item-value="id"
                              style="font-size: 12px"
                              return-id
                              dense
                              outlined
                              clearable
                              @change="templateWatcher"
                              :items="dropdown.payroll_template"
                            ></v-autocomplete>
                          </div> -->
                          <div
                            style="
                              width: 210px;
                              height: 40px;
                              margin-left: 15px;
                            "
                          >
                            <v-text-field
                              class="attendance-start-date"
                              dense
                              label="Periode"
                              type="month"
                              name="monthperiod"
                              style="height: 37px"
                              step="1"
                              v-model="paramAPI.period"
                              @change="periodWatcher"
                              outlined
                              clearable
                            />
                          </div>

                          <div style="width: 150px; margin-left: 15px">
                            <v-select
                              label="Status"
                              v-model="paramAPI.status_id"
                              :items="dropdown.status"
                              style="font-size: 12px"
                              item-text="name"
                              item-value="id"
                              return-id
                              dense
                              clearable
                              outlined
                              @change="statusWatcher"
                            ></v-select>
                          </div>
                        </div>
                      </v-toolbar>
                    </div>
                  </v-col>

                  <v-col cols="12" style="width: 100%">
                    <v-data-table
                      v-if="result.length > 0 || !skeleton_loading"
                      mobile-breakpoint="0"
                      fixed-header
                      height="50vh"
                      :headers="headers"
                      style="cursor: pointer"
                      :items="result"
                      :loading="loading"
                      @click:row="rowClick"
                      item-key="id"
                      :options.sync="options"
                      :footer-props="{
                        showFirstLastPage: true,
                        showCurrentPage: true,
                        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                      }"
                      :server-items-length="totalData"
                      :page="paramAPI.page"
                      @update:page="updatePage"
                      :items-per-page="paramAPI.itemsPerPage"
                      @update:items-per-page="updateItemPerPage"
                    >
                      <!-- <template v-slot:[`item.document_no`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.document_no }}
                        </div>
                      </template>
                      <template v-slot:[`item.created_at`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.created_at }}
                        </div>
                      </template>
                      <template v-slot:[`item.period_year`]="{ item }">
                        <div :style="`font-size:12px; width:30px; padding: 0;`">
                          {{ item.period_year }}
                        </div>
                      </template>
                      <template v-slot:[`item.period_month`]="{ item }">
                        <div :style="`font-size:12px; width:65px; padding: 0;`">
                          {{ monthName(item.period_month) }}
                        </div>
                      </template>
                      <template v-slot:[`item.status`]="{ item }">
                        <div
                          :style="`text-transform:uppercase; font-size:12px; font-weight:bolder; padding: 0; display: flex; flex-direction: row; color:${statusColor(
                            item.status_request
                          )};`"
                        >
                          {{ statusName(item.status) }}
                        </div>
                      </template> -->
                    </v-data-table>
                    <v-skeleton-loader
                      v-else
                      style="width: 100%"
                      type="table-heading, table-tbody, table-tfoot"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>

        <v-dialog v-model="dialog" width="500" persistent>
          <v-form
            :disabled="loading"
            ref="entryForm"
            @submit.prevent="generatePayroll"
            style="position: relative"
          >
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                GENERATE PAYROLL
              </v-card-title>

              <v-card-text class="d-flex justify-center align-center">
                <v-row style="padding: 10px 40px">
                  <!-- <v-col
                    cols="12"
                    style="
                      padding: 0 0 0 10px;
                      display: flex;
                      justify-content: start;
                      align-items: center;
                      margin-top: 20px;
                    "
                  >
                    <v-col
                      cols="6"
                      style="
                        padding: 0;
                        justify-content: flex-start;
                        align-items: center;
                        display: flex;
                      "
                    >
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Generate semua employee
                      </p>
                      <v-checkbox
                        v-model="form.generate_all"
                        :false-value="false"
                        :true-value="true"
                        style="margin: 0 0 0 10px; height: 25px; padding: 0"
                      ></v-checkbox>
                    </v-col>
                  </v-col> -->
                  <v-col md="12" style="padding: 0; margin-top: 20px">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          class="text-left"
                          :style="`
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);`"
                        >
                          Perusahaan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-select
                          v-model="form.company"
                          :items="dropdown.company"
                          style="position: relative; font-size: 12px"
                          item-text="name"
                          item-value="id"
                          return-object
                          dense
                          clearable
                          outlined
                          @change="companyDialogWatcher"
                          :disabled="isDisableCompanyDropdown"
                          :rules="rules.companyRules"
                        ></v-select>
                      </v-col>
                    </div>
                  </v-col>
                  <v-col md="12" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          class="text-left"
                          :style="`
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);`"
                        >
                          Template Payroll
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-select
                          v-model="form.payroll_template"
                          :items="dropdown.payroll_template"
                          style="position: relative; font-size: 12px"
                          item-text="name"
                          item-value="id"
                          return-object
                          dense
                          clearable
                          outlined
                          :rules="rules.templateRules"
                        ></v-select>
                        <div
                          v-if="form.payroll_template != null"
                          :style="`
                        margin: 0;
                        font-size: 12px; position:relative; top:-22px; z-index:2;`"
                        >
                          <span style="font-weight: bold">
                            Jabatan yang akan digenerate:
                          </span>
                          <br />
                          <!-- {{ form.payroll_template.employee_level_name }} -->
                          <!-- <div
                            v-for="(item, index) in form.payroll_template
                              .employee_level_name"
                            :key="index"
                            class="d-flex"
                            style="border: 1px solid black"
                          >
                            <v-chip class="ma-1" x-small>
                              {{ item }}
                            </v-chip>
                          </div> -->
                          <v-autocomplete
                            v-model="form.payroll_template.employee_level_name"
                            :items="form.payroll_template.employee_level_name"
                            chips
                            multiple
                            readonly
                            small-chips
                            append-icon=""
                            style="margin: 0; position: relative; top: -15px"
                          />
                        </div>
                      </v-col>
                    </div>
                  </v-col>
                  <!-- <v-col md="12" style="padding: 0">
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          class="text-left"
                          :style="`
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, ${
                          !form.generate_all ? '0.5' : '0.3'
                        });`"
                        >
                          Pilih Karyawan
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-autocomplete
                          v-model="form.employee_id"
                          :items="dropdown.employee"
                          :search-input.sync="employeeSearch"
                          :disabled="form.generate_all"
                          style="margin: 0"
                          label="ketik nama"
                          item-text="name"
                          item-value="id"
                          return-id
                          dense
                          outlined
                          clearable
                        >
                          <template v-slot:item="data">
                            <template>
                              <v-list-item-avatar>
                                <img
                                  :src="`${data.item.photo}`"
                                  @error="imgError(data.item)"
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ data.item.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ data.item.company_name }}-{{
                                    data.item.nik
                                  }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </div>
                  </v-col> -->
                  <v-col
                    md="12"
                    :style="`padding: 0; ${
                      form.payroll_template != null
                        ? 'position:relative; top:-30px;'
                        : ''
                    }`"
                  >
                    <div style="padding: 0 10px">
                      <v-col cols="12" style="padding: 0">
                        <p
                          class="text-left"
                          :style="`
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);`"
                        >
                          Periode
                        </p>
                      </v-col>
                      <v-col cols="12" style="padding: 0">
                        <v-text-field
                          class="attendance-start-date"
                          dense
                          type="month"
                          name="monthperiod"
                          style="height: 37px"
                          step="1"
                          v-model="form.start_date"
                          outlined
                          clearable
                          :rules="rules.periodRules"
                        />
                      </v-col>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="error"
                  text
                  outlined
                  @click="dialog = false"
                  :loading="loading"
                >
                  Batal
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="
                    permission.create_perm == 1 && permission.update_perm == 1
                  "
                  color="primary"
                  type="submit"
                  text
                  outlined
                  :loading="loading"
                >
                  Generate
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
        style="height: 80vh"
      >
        <v-card
          v-if="showOtpInput"
          elevation="5"
          class="ma-auto position-relative"
          style="max-width: 500px; background-color: rgba(240, 240, 240, 0.7)"
        >
          <v-card-text
            style="padding: 15px 15px 0 15px; margin: 0"
            class="d-flex justify-center align-center"
          >
            <v-row style="padding: 10px 40px">
              <v-col cols="12" style="padding: 0">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-center"
                      :style="`
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);`"
                    >
                      MASUKKAN KODE OTP
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-otp-input
                      v-model="otp"
                      :disabled="loading || timer === null"
                      length="6"
                    ></v-otp-input>
                    <v-overlay absolute :value="loading">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-overlay>
                  </v-col>
                  <v-col
                    v-if="this.timer !== null"
                    cols="12"
                    style="padding: 0"
                  >
                    <p
                      class="text-center"
                      :style="`
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.6);`"
                    >
                      {{ this.timer }}
                    </p>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <div
            class="d-flex justify-center align-center"
            style="padding: 0 50px"
          >
            <v-btn
              v-if="this.timer !== null"
              @click="verifyOTP"
              :loading="loading"
              class="white--text custom-btn"
              color="rgba(4, 123, 202, 0.979)"
              type="button"
            >
              Verify
            </v-btn>
            <v-btn
              v-else
              @click="generateOTP"
              class="white--text custom-btn"
              color="rgba(4, 123, 202, 0.979)"
              type="button"
            >
              Generate Ulang
            </v-btn>
          </div>
        </v-card>
        <v-form
          v-else
          ref="loginForm"
          @submit.prevent="loginPayroll"
          style="position: relative; width: 500px"
          :disabled="loading"
        >
          <v-card
            elevation="5"
            style="
              padding-top: 20px;
              background-color: rgba(240, 240, 240, 0.7);
            "
          >
            <!-- <p class="white lighten-2 d-flex justify-center align-center">LOGIN</p> -->

            <v-card-text class="d-flex justify-center align-center">
              <v-row style="padding: 10px 40px">
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        class="text-left"
                        :style="`
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.8);`"
                      >
                        Masukkan password untuk mengakses halaman ini
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-model="loginForm.password"
                        type="password"
                        dense
                        style="height: 37px"
                        outlined
                        clearable
                        light
                      />
                    </v-col>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <div
              class="d-flex justify-center align-center"
              style="padding: 0 50px"
            >
              <v-btn
                :loading="loading"
                class="white--text custom-btn"
                color="rgba(4, 123, 202, 0.979)"
                type="submit"
              >
                Submit
              </v-btn>
            </div>
          </v-card>
        </v-form>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      YOU CANNOT ACCESS THIS PAGE
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../services/buildType'
// import moment from 'moment'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'payroll',
  data: () => ({
    config: require('../../../services/config.json')[env],
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuthApi: buildType.apiURL('oauth'),
    build: process.env.VUE_APP_BUILD_TYPE,
    dialog: false,
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    showOtpInput: null,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      order_by: 'created_at',
      order_type: 'desc',
      company_id: null,
      employee_id: null,
      status_id: null,
      period: null,
      payroll_template_id: null
    },
    totalData: 0,
    employeeSearch: null,
    selectEmployee: null,
    options: {},
    actionValue: 0,
    headers: [
      // {
      //   text: 'No',
      //   value: 'no',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'No. Dokumen',
        value: 'document_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Template',
        value: 'payroll_template_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tahun',
        value: 'period_year',
        sortable: false
      },
      {
        text: 'Bulan',
        value: 'period_month',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        sortable: false
      },
      {
        text: 'Dibuat pada',
        value: 'created_at',
        align: 'left',
        sortable: false
      }
      // {
      //   text: 'Diupdate pada',
      //   value: 'updated_at',
      //   align: 'left',
      //   sortable: false
      // }
    ],
    result: [],
    loading: false,
    dropdown: {
      company: [],
      employee: [],
      status: [],
      payroll_template: []
    },
    loginForm: {
      email: '',
      password: ''
    },
    form: {
      company: null,
      payroll_template: null,
      start_date: null
      // employee_id: null
      // is_send_payslip: null
    },
    rules: {
      tmplateRules: [],
      companyRules: [],
      periodRules: []
    },
    otp: null,
    request_id: null,
    timer: null,
    xInterval: null,
    skeleton_loading: true
  }),

  async mounted() {
    this.appLevel = this.config.application.hr.userLevel
    // this.appLevel.administrator =
    //   this.config.application.all.userLevel.administrator

    this.getDataMenu(this.$route.path)
    // if (this.getLoginPayroll) {
    //   this.verifyAccessToken()
    // }
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile', 'getLoginPayroll', 'getPayrollPageQuery'])
  },
  watch: {
    employeeSearch(val) {
      if (val !== null) {
        if (val !== '') {
          val && val !== this.selectEmployee && this.querySelections(val)
        } else {
          this.selectEmployee = null
          this.dropdown.employee = []
        }
      } else {
        this.selectEmployee = null
        this.dropdown.employee = []
      }
    }
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setLoginPayroll', 'setPayrollPageQuery']),
    async verifyAccessToken() {
      this.loading = true
      await axios
        .post(
          `${this.oAuthApi}otp/access_token/verify`,
          {},
          {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          }
        )
        .then((res) => {
          console.log('verify access token>>>', res)
          if (res.data.status_code === '00') {
            // this.getDataMenu(this.$route.path)

            this.initDataForLayout()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
            this.setLoginPayroll(null)
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async loginPayroll() {
      this.loading = true
      this.loginForm.email = this.getUserProfile.employee.email
      await axios
        .post(`${this.oAuthApi}user/login`, this.loginForm)
        .then((res) => {
          console.log('login page>>>', res)
          if (res.data.status_code === '00') {
            this.generateOTP()
            // // this.getDataMenu()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    setCountdown(dateTime) {
      var dateTimeNow = dateTime
      const self = this
      this.xInterval = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime()

        // Find the distance between now and the count down date
        var distance = dateTimeNow - now

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24))
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        var seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Output the result in an element with id="demo"
        self.timer =
          days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(self.xInterval)
          self.timer = null
        }
        console.log(self.timer)
      }, 1000)
    },
    async generateOTP() {
      this.loading = true
      await axios
        .post(`${this.oAuthApi}otp/generate`, {
          application_id: 21,
          application_name: 'HRIS'
        })
        .then((res) => {
          console.log('generate otp>>>', res)
          if (res.data.status_code === '00') {
            this.request_id = res.data.request_id
            this.showOtpInput = setTimeout(() => {
              const dateTime = new Date().getTime() + 1 * 60000
              this.setCountdown(dateTime)
            }, 0)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async verifyOTP() {
      this.loading = true
      await axios
        .post(`${this.oAuthApi}otp/verify`, {
          request_id: this.request_id,
          otp: this.otp
        })
        .then((res) => {
          console.log('verify otp>>>', res)
          if (res.data.status_code === '00') {
            // this.access_token = res.data.data.x_access_token
            setTimeout(() => {
              this.loading = false
              // this.showMsgDialog('warning', 'Invalid OTP', false)
              this.showOtpInput = false
              this.setLoginPayroll(res.data.access_token)
              // this.getDataMenu(this.$route.path)
              setTimeout(() => {
                this.initDataForLayout()
              }, 100)
              clearInterval(this.xInterval)
            }, 1000)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
            this.loading = false
          }
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async getDataMenu(path) {
      this.isLoadingPage = true
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: path
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId
              // ||
              // application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null && this.userLevel !== undefined) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            // this.initDataForLayout()
            if (this.getLoginPayroll) {
              this.verifyAccessToken()
            }
          }
        }
      }
      this.isLoadingPage = false
    },
    async initDataForLayout() {
      if (
        this.userLevel.user_user_level.allowed_company.find(
          ({ id }) => id == this.getUserProfile.employee.company.plant_id
        ) !== undefined
      ) {
        this.paramAPI.company_id = Number(
          this.getUserProfile.employee.company.plant_id
        )
      } else {
        this.paramAPI.company_id = Number(
          this.userLevel.user_user_level.allowed_company[0].id
        )
      }
      // if (this.userLevel.is_support) {
      //   this.paramAPI.company_id = 0
      // } else {
      //   this.paramAPI.company_id = Number(
      //     this.getUserProfile.employee.company.plant_id
      //   )
      // }
      console.log('this.paramAPI>>>', this.paramAPI)
      if (this.getPayrollPageQuery !== null) {
        this.paramAPI = this.getPayrollPageQuery
      }
      console.log('getPayrollPageQuery>>>', this.getPayrollPageQuery)
      setTimeout(async () => {
        this.initDropdown()
        await this.getDataFromApi(5)
      }, 200)
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, pText, pOptions) {
      let additional = ''
      if (pText !== undefined) {
        additional = '<span style="font-size:12px;">' + pText + '</span>'
      }
      const options = {
        position: 'center',
        icon: pModalType,
        html:
          '<strong style="font-size:18px;">' +
          pStatusMsg +
          '</strong>' +
          '<br/>' +
          additional,
        showConfirmButton: true,
        showCancelButton: pBtnCancel,
        cancelButtonText: 'No'
      }
      if (pOptions !== undefined) {
        Object.assign(options, {
          width: pOptions.width !== undefined ? pOptions.width : null,
          grow: pOptions.grow !== undefined ? pOptions.grow : null
        })
        if (pOptions.heightAuto !== undefined) {
          Object.assign(options, {
            heightAuto:
              pOptions.heightAuto !== undefined ? pOptions.heightAuto : null
          })
        }
      }
      return new Promise((resolve) => {
        this.$swal.fire(options).then((r) => {
          resolve(r)
        })
      })
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    searchEnter() {
      this.resetSomeParam()
      this.getDataFromApi(4)
    },
    async periodWatcher(v) {
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi(3)
      }, 100)
    },
    async statusWatcher(v) {
      this.paramAPI.status_id = v
      this.resetSomeParam()
      setTimeout(async () => {
        await this.getDataFromApi(2)
      }, 100)
    },
    async initDropdown() {
      this.getDropdownCompany()
      this.dropdownStatus()
      this.dropdownPayrollTemplate(this.paramAPI.company_id)
    },
    companyWatcher(v) {
      this.resetSomeParam()
      this.dropdownPayrollTemplate(v)
      this.getDataFromApi(1)
    },
    companyDialogWatcher(v) {
      let company_id = null
      if (v !== null) {
        company_id = v.id
      }
      if (this.form != null) {
        this.form.payroll_template = null
      }
      this.dropdownPayrollTemplate(company_id)
    },
    // templateWatcher() {
    //   this.getDataFromApi()
    // },
    dropdownStatus() {
      this.dropdown.status = [
        {
          id: '-2',
          name: 'Canceled'
        },
        {
          id: '-1',
          name: 'Rejected'
        },
        {
          id: '0',
          name: 'Draft'
        },
        {
          id: '1',
          name: 'Waiting Approval'
        },
        {
          id: '2',
          name: 'Approved'
        }
      ]
    },
    async getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async dropdownPayrollTemplate(v) {
      let url = `${this.hrsApi}master/universal/payrolltemplate/dropdown?source=payroll`
      // const filter = []
      if (v !== null) {
        // &logged_employee_id=${Number(this.getUserProfile.employee.id)}
        // filter.push(`{"company_id": ${v}}`)
        url = url + `&company_id=${v}`
      }
      // url = url + `&filter=[${filter}]`
      axios
        .get(url)
        .then((res) => {
          this.dropdown.payroll_template = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getDataFromApi(val) {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable(val) {
      console.log('hereeeee 1>>>>', val)
      let url = `${this.hrsApi}employee/payroll_document/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&order_by=${this.paramAPI.order_by}&order_type=${
        this.paramAPI.order_type
      }`
      if (this.paramAPI.status_id !== null) {
        url = url + `&status=${this.paramAPI.status_id}`
      }
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.period !== null) {
        url =
          url +
          `&period_month=${this.paramAPI.period.split('-')[1]}&period_year=${
            this.paramAPI.period.split('-')[0]
          }`
      }
      this.setPayrollPageQuery(this.paramAPI)
      return await new Promise((resolve) => {
        axios
          .get(url, {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          })
          .then((res) => {
            console.log('payroll list>>>>', res.data)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      if (this.permission.read_perm == 1) {
        setTimeout(() => {
          this.$router.push(`/hr/payroll/detail/${pItem.id}?limit=$offset=`)
        }, 100)
      }
    },
    querySelections(v) {
      let url = `${this.hrsApi}employee/dropdown?keyword=${v}`
      if (this.form.company !== null) {
        url = url + `&company_id=${this.form.company.id}`
      }
      axios
        .get(url)
        .then((res) => {
          console.log(res)
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            return (this.dropdown.employee = resArr)
          }
          return (this.dropdown.employee = [])
        })
        .catch((err) => {
          this.dropdown.employee = []
          return console.log(err)
        })
    },

    generatePayroll() {
      this.rules.periodRules = [(v) => !!v || 'Period is required']
      this.rules.companyRules = [(v) => !!v || 'Company is required']
      this.rules.templateRules = [(v) => !!v || 'Template is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          const payload = {
            company_id: self.form.company.id,
            company_name: self.form.company.name,
            period_month:
              self.form.start_date !== null
                ? Number(self.form.start_date.split('-')[1])
                : null,
            period_year:
              self.form.start_date !== null
                ? self.form.start_date.split('-')[0]
                : null,
            payroll_template_id: self.form.payroll_template.id,
            employee_level_id: self.form.payroll_template.employee_level_id
            // is_send_payslip: self.form.is_send_payslip
          }
          // if (self.form.employee_id !== null) {
          //   Object.assign(payload, {
          //     employee_id: self.form.employee_id
          //   })
          // }

          console.log('generate>>>>', payload, self.selectEmployee, self.form)
          self.startGenerate(payload)
        }
      })
    },
    startGenerate(payload) {
      this.showMsgDialog('question', 'Generate payroll sekarang', true).then(
        async (res) => {
          if (res.isConfirmed) {
            this.loading = true
            await axios
              .post(`${this.hrsApi}employee/payroll/generate`, payload, {
                headers: {
                  'x-access-token': this.getLoginPayroll
                }
              })
              .then((res) => {
                this.loading = false
                // this.closeGenerateDialog()
                if (res.data.status_code === '00') {
                  let success = 0
                  let failed = 0
                  // let status = 'info'
                  let successMsg = ''
                  let failedMsg = ''

                  for (let i = 0; i < res.data.data.length; i++) {
                    if (res.data.data[i].result.status_code == '00') {
                      success += 1
                      successMsg =
                        successMsg +
                        `<span>${i + 1}. ${res.data.data[i].name} [${
                          res.data.data[i].result.status_msg
                        }] </span><br/>`
                    } else {
                      failed += 1
                      failedMsg =
                        failedMsg +
                        `<span>${i + 1}. ${res.data.data[i].name} [${
                          res.data.data[i].result.status_msg
                        }] </span><br/>`
                    }
                  }
                  // if (success > 0) {
                  //   status = 'success'
                  // }
                  const additionalMsg =
                    '<div style="overflow: auto; max-height: 400px; text-align:left; margin-top:10px;">' +
                    '<div style="margin-bottom:5px; color:green;"> ' +
                    `<div style="font-weight:bolder; font-size:13px; text-align:center;">Update berhasil (${success}):</div><span>${successMsg}</span>` +
                    '</div>' +
                    '<div style="color:red;">' +
                    `<div style="font-weight:bolder; font-size:13px; text-align:center;">Update gagal (${failed}):</div><span>${failedMsg}</span>` +
                    '</div>' +
                    '</div>'

                  this.getDataFromApi(8)
                  const options = { heightAuto: false }
                  if (failed > 0) {
                    Object.assign(options, {
                      width: '700px'
                    })
                  }
                  this.showMsgDialog(
                    'success',
                    `${res.data.status_msg}`,
                    false,
                    additionalMsg,
                    options
                  )
                } else {
                  if (res.data.status_msg === 'Parameter value has problem') {
                    this.showMsgDialog(
                      'error',
                      'Invalid input, Parameter value has problem',
                      false
                    )
                  } else {
                    this.showMsgDialog('error', res.data.status_msg, false)
                  }
                }
              })
              .catch((err) => {
                this.showMsgDialog(
                  'error',
                  err
                    ? 'Something went wrong, Please contact an admin!'
                    : 'Something went wrong, Please contact an admin!',
                  false
                )
                return (this.loading = false)
              })
          }
        }
      )
    },
    openGenerateDialog() {
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    closeGenerateDialog() {
      this.$refs.entryForm.reset()
      setTimeout(() => {
        this.dialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi(7)
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi(6)
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    },

    statusName(val) {
      switch (val) {
        case -2:
          return 'CANCELED'
        case -1:
          return 'REJECTED'
        case 0:
          return 'DRAFT'
        case 1:
          return 'WAITING APPROVAL'
        case 2:
          return 'APPROVED'
        default:
          break
      }
    },
    statusColor(val) {
      switch (val) {
        case -2:
          return 'red'
        case -1:
          return 'red'
        case 0:
          return 'blue'
        case 1:
          return 'orange'
        case 2:
          return 'green'
        default:
          break
      }
    },
    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJB'
      }
    },
    monthName(val) {
      switch (val) {
        case 1:
          return 'JANUARI'
        case 2:
          return 'FEBRUARI'
        case 3:
          return 'MARET'
        case 4:
          return 'APRIL'
        case 5:
          return 'MAY'
        case 6:
          return 'JUNI'
        case 7:
          return 'JULI'
        case 8:
          return 'AGUSTUS'
        case 9:
          return 'SEPTEMBER'
        case 10:
          return 'OKTOBER'
        case 11:
          return 'NOVEMBER'
        case 12:
          return 'DESEMBER'
      }
    },
    imgError(xItem) {
      const xId = xItem.id
      const xIndex = this.dropdown.employee.findIndex((item) => item.id === xId)
      this.dropdown.employee[xIndex].photo =
        'https://cdn-icons-png.flaticon.com/512/61/61173.png'
    }
  }
}
</script>
<style lang="scss">
.custom-btn {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  border-radius: 5px;
  height: 40px;
}
#payrollList {
  position: relative;
  .payroll-list-dev {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .payroll-list-toolbar-1 {
      .payroll-list-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #payrollList {
    .payroll-list-dev {
      .payroll-list-toolbar-1 {
        .payroll-list-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
